import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import track from "../../components/track"
import homeMain from "../../images/hero-hcp-home.png"
import homeMainLarge from "../../images/hero-hcp-home-large.png"
import homeMainMobile from "../../images/hero-hcp-home-mobile.png"
import homeButton from "../../images/homepage-button-hcp.png"
// import Hero from "../components/hero"
// import PDFLibrary from "../components/pdfLibrary"
import SEO from "../../components/seo"

const pageTitle = 'MOVANTIK® (naloxegol): Official Health Care Professional Website';
const pageDescription = 'Take action with MOVANTIK, a prescription medication used to treat adults with opioid-induced constipation. Visit the official HCP website for more information.';
const pageName = "Home";

const IndexPage = () => {

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="hcp" pageSection="hcp-home" pageClass="home"  pageName={pageName}>
      <SEO title="Home" />
      <div className="hero">
      <img src={homeMainLarge} className="hero-img large" alt="" />
        <img src={homeMain} className="hero-img medium" alt="" />
        <img src={homeMainMobile} className="hero-img mobile" alt="" />
        <div className="container">
          <div className="headlines">
            <div className="eyebrow">For patients experiencing constipation from opioids for chronic non-cancer pain</div>
            <h1>Anticipate OIC.<br />Treat with confidence.</h1>
            <img src={homeButton} className="home-btn" alt="Number 1 Prescribed Oral Treatment of its kind" />
          </div>
        </div>
      </div>
      <div className="container home-main-content">
        <div className="home-callouts">
          <div className="home-callout">
            <Link to="/hcp/burden-of-oic/" id="hcp-home-btn-efficacy" className="button" onClick={() => track("HCP Home page", "button click", "HCP Homepage-Explore Same-day relief")}>
              <div className="btn-text">Is it possible your patients are <span className="bold">suffering silently from OIC?</span></div>
              <div className="btn-arrow"></div>
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="end-matter">
          {/* <div className="definitions">MOA=mechanism of action; PAMORA=peripherally acting mu-opioid receptor antagonist.</div> */}
          <div className="references"><span className="bold">References: 1.</span>&nbsp;MOVANTIK<sup>®</sup> (naloxegol). Prescribing information. Valinor Pharma, LLC; 2023. <span className="bold">2.</span> Data on file. Prescription data. Valinor Pharma, LLC; 2023.</div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
